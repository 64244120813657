import { EVENT_TYPE } from "../storecontextlayer/enums/event-type";
class ContextBanner extends lm.Composant {
  constructor(componentName) {
    super(componentName);
    const contextBannerSelector = document.querySelector(`[data-selectorid='context-banner']`);
    if (!contextBannerSelector) return;
    const postcodeBannerSelector = contextBannerSelector.querySelector(".js-context-postcode-button");
    const storeHeaderSelector = contextBannerSelector.querySelector(".js-context-store-name-button");
    const openContextLayerEvent = new Event("openStoreContextLayer");
    postcodeBannerSelector?.addEventListener(EVENT_TYPE.CLICK, () => {
      window.dispatchEvent(openContextLayerEvent);
    });
    storeHeaderSelector.addEventListener(EVENT_TYPE.CLICK, () => {
      window.dispatchEvent(openContextLayerEvent);
    });
  }
}
lm.DOMReady(() => {
  new ContextBanner('contextbanner');
});